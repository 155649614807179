import Image from 'next/image';
import Link from 'next/link';
import { Chat } from '@mui/icons-material';
import { useState } from 'react';
import Dislike from '../icons/Dislike';
import Like from '../icons/Like';
import Badge from './badge';

function card({ idx, resource, enabledTypes, setResourceId, tb }) {
  const [distance, setDistance] = useState(0);
  const [initialCoordinates, setInitialCoordinates] = useState(0);
  const [dragging, setDragging] = useState(false);

  return (
    <Link href={`/resource/${resource.id}`}>
      <a
        key={resource.id}
        onClick={(e) => (distance > 10 ? e.preventDefault() : null)}
        onDragStart={(e) => {
          e.preventDefault();
          setDistance(0);
          setInitialCoordinates(e.clientX);
          setDragging(true);
        }}
        onMouseDown={(e) => setDistance(0)}
        onMouseUp={(e) => setDragging(false)}
        onMouseMove={(e) => {
          if (initialCoordinates && dragging) {
            setDistance(Math.abs(initialCoordinates - e.clientX));
          }
        }}
        role="link"
        onKeyPress={() => {}}
        tabIndex={0}
        className="p-2 overflow-hidden bg-th-background-secondary flex flex-col rounded card">
        <div className="flex">
          <Image height={128} width={128} src="/logo512.png" alt="resource icon" />
          <div className="ml-2 w-2/3 flex flex-col">
            {resource.title} {enabledTypes.length > 1 && `- ${resource.type}`}
            <p className="opacity-75">{resource.subtitle}</p>
          </div>
        </div>
        {resource?.badges?.length > 0 && <hr className="mt-2" />}
        {resource?.badges?.length > 0 && (
          <div className="mt-2">
            <div className="flex">
              <div className="flex w-1/2 overflow-x-auto">
                {resource?.badges?.map((b) => (
                  <Badge key={b} t={tb} title={b} />
                ))}
              </div>
              <div className="flex w-1/2 items-center">
                <div className="ml-auto flex">
                  {resource?.reviewsCount > 0 && (
                    <div className="my-auto flex">
                      <p className="">{resource?.reviewsCount}</p>
                      <Chat style={{ fontSize: '1.25rem', marginLeft: '0.3rem' }} />
                    </div>
                  )}
                  {resource?.rating?.likes > 0 && (
                    <div className="my-auto flex">
                      <p className="ml-2 my-auto">{resource?.rating?.likes}</p>
                      <Like style={{ height: '1.25rem', marginLeft: '0.3rem' }} />
                    </div>
                  )}
                  {resource?.rating?.dislikes > 0 && (
                    <div className="my-auto flex">
                      <p className="ml-2 my-auto">{resource?.rating?.dislikes}</p>
                      <Dislike style={{ height: '1.25rem', marginLeft: '0.3rem' }} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </a>
    </Link>
  );
}

card.propTypes = {};

export default card;
