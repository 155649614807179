import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

function badge(props) {
  return (
    <Tooltip title={props.t(props.title)}>
      <img className="mr-2" alt={props.t(props.title)} style={{ height: '1.5rem' }} src={`/images/svg/badges/${props.title}.svg`} />
    </Tooltip>
  );
}

badge.propTypes = {
  t: PropTypes.func,
  title: PropTypes.string,
};

export default badge;
